import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataorchService } from "@services/dataorch.service";
import { PlanningService } from "@services/planning.service";
import dayjs from "dayjs";
import _ from "underscore";

@Component({
    selector: '[linehaul-mark-ready-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class MarkLinehaulRouteReadyForm extends BaseComponent {
    service: PlanningService

    public updating: boolean = false

    @Input() job

    @Input() onClose
    @Input() onOk

    constructor() {
        super()
        this.service = new PlanningService(this.api)
    }

    onConfirm() {
        this.updating = true
        // determine days

        this.service.updateJobType(this.job.id, 'normal').subscribe(res => {
            console.log(res)
            this.updating = false
            this.job.type = 'normal'
            if (this.onOk)
                this.onOk()
            else
                this.onClose && this.onClose()
        })
    }
}