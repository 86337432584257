<div class="ghost-load-header">
  <div class="btn-close center-children clickable" (click)="onBtnClose()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
  </div>
  <div class="page-title flex1">Create Manual Load</div>
  <button nz-button class="btn-clone-from-route" (click)="onBtnCloneFromRoute()">Clone From Route</button>
</div>
<div class="separator h"></div>
<div class="ghost-load-content">
  <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
    <div nz-col class="gutter-row" nzXs="24" nzSm="24" nzMd="15" nzLg="15">
      <form nz-form [formGroup]="formInput" class="form-detail">
        <div class="group no-padding" style="margin-bottom: 100px">
          <div class="bottom10">
            <div class="form-label-v2 bottom5 medium color-text">
              Vehicle Type
            </div>
            <div style="width: 300px;">
              <div vehicle-selector [value]="vehicleTypeValue" (valueChange)="onVehicleTypeChange($event)" [withOptions]="true"></div>
            </div>
          </div>
          <div *ngFor="let key of ['addressType']">
            <div class="form-label-v2 bottom5">{{getLabel(key)}}</div>
            <nz-form-item>
              <nz-form-control>
                <nz-radio-group nzBackdrop="true" nzButtonStyle="solid"
                  [formControlName]="key" >
                  <label nz-radio-button *ngFor="let item of listAddressTypes"
                      [nzValue]="item.value">{{item.label}}</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <ng-container *ngIf="!isCloningFromLoad">
          <div *ngFor="let groupKey of ['pickInfos', 'dropInfos']" [formArrayName]="groupKey">
            <div *ngIf="canAddMultiStop" class="flex top20 bottom20">
              <div class="f15 medium">{{ getLabel(groupKey) }}</div>
              <div class="left20">
                <button nz-button nzSize="small" (click)="onBtnAddStop(groupKey)">
                  <i nz-icon nzType="plus"></i>Add Location
                </button>
              </div>
            </div>
            <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="top10 bottom10">
              <div nz-col nzSm="24" nzMd="12" class="medium">{{ getLabelAddressType(groupKey) }}</div>
              <div nz-col nzSm="24" nzMd="12" class="medium">{{ groupKey == 'pickInfos' ? 'Pickup date' : 'Delivery date' }}</div>
            </div>
            <form *ngFor="let item of getArrayControls(groupKey); let pickInfoIndex = index" nz-form [formGroupName]="pickInfoIndex">
              <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="bottom10">
                <div nz-col nzSm="24" nzMd="12">
                  <ng-container *ngIf="isFullAdrressSelected">
                    <nz-form-item>
                      <nz-form-control>
                        <form-address [formControlName]="'address'"></form-address>
                      </nz-form-control>
                    </nz-form-item>
                  </ng-container>
                  <ng-container *ngIf="!isFullAdrressSelected">
                    <div zipcode-selector placeholder="Pickup Zipcode" [size]="'sm'" (onNewValue)="onZipcodeChange(groupKey, pickInfoIndex, $event)"></div>
                  </ng-container>
                </div>
                <div nz-col nzSm="20" nzMd="12">
                  <div class="flex">
                    <nz-form-item>
                      <nz-form-control>
                        <nz-range-picker [formControlName]="'rangeDate'" [nzFormat]="'yyyy/MM/dd'"></nz-range-picker>
                      </nz-form-control>
                    </nz-form-item>
                    <div *ngIf="shouldShowRemoveStop(groupKey)" class="left20">
                      <i style="cursor: pointer; color: red; font-size: 20px; position: relative; top: 6px" 
                        (click)="onBtnRemoveStop(groupKey, pickInfoIndex)" 
                        nz-icon nzType="close" 
                        nzTheme="outline"></i>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          </ng-container>
          <ng-container *ngIf="isCloningFromLoad">
            <div *ngFor="let groupKey of ['stops']" [formArrayName]="groupKey">
              <form *ngFor="let item of getArrayControls(groupKey); let pickInfoIndex = index" nz-form [formGroupName]="pickInfoIndex">
                <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }" class="bottom10">
                  <div nz-col nzSm="24" nzMd="12">
                    <ng-container *ngIf="isFullAdrressSelected">
                      <div>Stop {{ pickInfoIndex + 1 }} : {{  getStopAddress(pickInfoIndex) }}</div>
                    </ng-container>
                  </div>
                  <div nz-col nzSm="20" nzMd="12">
                    <div class="flex">
                      <nz-form-item>
                        <nz-form-control>
                          <!-- <nz-range-picker [formControlName]="'rangeDate'" [nzFormat]="'yyyy/MM/dd'"></nz-range-picker> -->
                          <nz-range-picker style="margin-right: 20px;" *ngIf="'range' as childKey"
                            [formControlName]="'rangeDate'"
                            [nzShowTime]="{ nzFormat: 'HH:mm', nzMinuteStep: 15 }"
                            nzFormat="MM-dd-yyyy HH:mm">
                          </nz-range-picker>
                        </nz-form-control>
                      </nz-form-item>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
          <div>
            <div>
              <span class="f15 medium">Shipping Items</span>
              <span class="left10 small-button f13" (click)="onAddNewItem('Pallet')">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
                Pallets
              </span>
            </div>
            <div style="max-width: 800px;">
              <div *ngIf="items && items.length > 0 " nz-row [nzGutter]="{md:8, xs: 2}" >
                <div nz-col nzXs="1"></div>
                <div nz-col nzXs="3" nzSm="0" class="label">Qty<span class="label-mark-required"></span></div>
                <div nz-col nzXs="0" nzSm="3" class="label">Quantity<span class="label-mark-required"></span></div>
                <div nz-col nzXs="0" nzSM="4" class="label">Packaging</div>
                <div nz-col nzXs="3" nzSM="0" class="label">Pkg</div>
                <div nz-col nzXs="3" class="label">Width<span class="label-mark-required"></span></div>
                <div nz-col nzXs="3" class="label">Length<span class="label-mark-required"></span></div>
                <div nz-col nzXs="3" class="label">Height<span class="label-mark-required"></span></div>
                <div nz-col nzXs="3" class="label">Per Pallet Weight<span class="label-mark-required"></span></div>
                <div nz-col nzXs="3" class="label">Total Weight</div>
              </div>
              <div *ngFor="let item of items">
                <div quote-item [item]="item" (onDelete)="onDeleteItem($event)"></div>
              </div>
            </div>
          </div>
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 16, lg: 16 }">
            <div nz-col nzSm="24" nzMd="12">
              <button nz-button class="btn-create" [disabled]="isLoading" (click)="onBtnCreateLoad()">Create</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div nz-col nzXs="24" nzSm="24" nzMd="9" nzLg="9" style="display: flex;flex-direction: column;align-items: center;">
      <img class="empty-quote-img" src="/assets/img/freight-quote.svg" />
    </div>
  </div>
</div>



