<div class="bottom10">
    <div class="label">Vehicle<span class="label-mark-required"></span></div>
    <div vehicle-selector [(value)]="vehicleType" [quoting]="false"></div>
</div>
<div class="bottom10">
    <div class="label">Availability<span class="label-mark-required"></span></div>
    <input type="number" nz-input [(ngModel)]="availability" />
</div>
<div class="bottom10">
    <div class="label">Mileage Limit</div>
    <input type="number" nz-input [(ngModel)]="maxMileage" />
</div>
<div>
    <div class="label">Hour Limit</div>
    <input type="number" nz-input [(ngModel)]="maxHours" />
</div>