<div nz-row [nzGutter]="{md:8, xs: 2}">
    <div nz-col nzXs="1" class="text-center" style="padding-top: 5px;">
      <i style="cursor: pointer; color: red;" (click)="onDeleteItem()" nz-icon nzType="close" nzTheme="outline"></i>
    </div>
    <div nz-col nzXs="2">
      <nz-form-item>
        <nz-form-control>
            <input [ngClass]="{'validation-error': !item.quantity}" type="number" size="3" nz-input [(ngModel)]="item.quantity" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="3">
      <nz-form-item>
        <nz-form-control>
            <input disabled size="6" nz-input [value]="item.packaging">
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzXs="2">
      <nz-form-item>
        <nz-form-control>
            <input [ngClass]="{'validation-error': !item.length}" type="number" size="4" nz-input [(ngModel)]="item.length" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="2">
      <nz-form-item>
        <nz-form-control>
            <input [ngClass]="{'validation-error': !item.width}" type="number" size="4" nz-input [(ngModel)]="item.width" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="2">
      <nz-form-item>
        <nz-form-control>
            <input [ngClass]="{'validation-error': !item.height}" type="number" size="4" nz-input [(ngModel)]="item.height" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="3">
      <nz-form-item>
        <nz-form-control>
            <input nz-input [ngClass]="{'validation-error': !item.weight}" type="number" class="input-dimen" size="6" [(ngModel)]="item.weight" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="3">
      <nz-form-item>
        <nz-form-control>
            <input nz-input disabled="true" type="number" class="input-dimen" size="6" [ngModel]="getTotal(item)" (ngModelChange)="onChange()">
        </nz-form-control>
      </nz-form-item>      
    </div>
    <div nz-col nzXs="2">
      <nz-form-item>
        <nz-form-control>
            <input nz-input class="input-dimen" size="6" [(ngModel)]="item.freightClass">
        </nz-form-control>
      </nz-form-item>      
    </div>
</div>
