import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";

@Component({
    selector: '[add-vehicle-form]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class AddVehicleForm extends BaseComponent {
    availability: number = 0
    vehicleType: any = null
    maxMileage: number | null = null
    maxHours: number | null = null
}