<h2>
    Linehaul <code>{{ job.code }}</code> ready for outbound
</h2>
<div>
    Route will be switched from <nz-tag [nzColor]="job.type == 'ghost' ? 'purple': 'blue'">{{ job.type }}</nz-tag> to <nz-tag nzColor="green">normal</nz-tag>
</div>
<div>
    Load tender will show the actual pallet counts.
</div>
<div class="bottom10">
    Please resend carrier the most updated load tender.
</div>
<div class="center bottom10" style="text-align: center; font-weight: 500;">
    Are you sure you want to proceed?
</div>

<div class="buttons flex">
    <div class="right20">
        <button [disabled]="updating" nz-button (click)="onClose && onClose()">Cancel</button>
    </div>
    <div>
        <button nzType="primary" nz-button (click)="onConfirm()">Confirm</button>
    </div>
</div>