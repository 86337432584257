<form nz-form [formGroup]="formInput" class="form-detail" [formGroup]="formInput" nzLayout="vertical">
  <div *ngIf="'jobCode' as key">
    <nz-form-item>
      <nz-form-label>{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></nz-form-label>
      <nz-form-control>
        <input nz-input [placeholder]="getPlaceHolder(key)"
          (input)="onInputChanged($event, key)"
          [formControlName]="key"/>
      </nz-form-control>
    </nz-form-item>
  </div>
</form>

<div form-footer [onProgress]="onProgress" [nzIconCancel]="null" [nzIconOK]="null"
  [canClickOK]="needUpdate && !onProgress && !isLoading" [canClickCancel]="!onProgress" labelOK="Search"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>
