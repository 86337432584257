import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { environment } from '@env/environment';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import dayjs from 'dayjs';

@Component({
  selector: '[freight-quote-history-list]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss', '../index.scss']
})
export class FreightQuoteHistoryList extends BaseComponent {
    public isLoading: boolean = false
    filters: any = {
        dropoffZipcode: null
    }
    timeOut = null;
    list: any = null
    loading: boolean = false
    pickupZipcode: string = null
    dropoffZipcode: string = null
    filterOptions: any = {
        type: [{
            name: 'FTL',
            value: 'FTL'
        }, {
            name: 'LTL',
            value: 'LTL'
        }],
        status: [{
            name: 'Booked',
            value: 'BOOKED'
        }, {
            name: 'Rate available',
            value: 'SUCCESSFUL'
        }, {
            name: 'Rate not available',
            value: 'COMPLETED'
        }]
    }

    constructor(
        protected route: ActivatedRoute,
    ) {
        super();
    }
    
    
    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.filters = {}
            if (params['offset']) {
                this.filters['offset'] = params['offset']
            }
            if (params['limit']) {
                this.filters['limit'] = params['limit']
            }
            if (params['type']) {
                this.filters['type'] = params['type']
            }
            if (params['customer']) {
                this.filters['customer'] = params['customer']
            }
            if (params['status']) {
                this.filters['status'] = params['status']
            }
            if (params['pickupZipcode']) {
                this.filters['pickupZipcode'] = params['pickupZipcode']
            }
            if (params['dropoffZipcode']) {
                this.filters['dropoffZipcode'] = params['dropoffZipcode']
            }
            if (params['pickupDate']) {
                this.filters['pickupDate'] = Date.parse(params['pickupDate'] + 'T12:00:00.000Z')
            }
            if (params['quoteDate']) {
                this.filters['quoteDate'] = Date.parse(params['quoteDate'] + 'T12:00:00.000Z')
            }
            if (params['quoteId']) {
                this.filters['quoteId'] = params['quoteId']
            }
            this.loadRequests()
        });
    }

    loadRequests() {
        let url = environment.backendUrl + `/v2/pricing/quote/request`
        let queryParams = []
        let filtered = {}
        for (let key of Object.keys(this.filters)) {
            if (key.indexOf('Date') < 0 && this.filters[key]) {
                filtered[key] = this.filters[key]
                queryParams.push(`${key}=${this.filters[key]}`)
            }
        }
        if (this.filters.pickupDate) {
            filtered['pickupDate'] = DateUtil.displayLocalTime(this.filters.pickupDate, {format: Const.FORMAT_GUI_DATE_V3})
            const d = dayjs(this.filters.pickupDate).startOf('day')
            queryParams.push(`pickupTimeFrom=${d.toISOString()}`)
            queryParams.push(`pickupTimeTo=${d.add(1, 'day').toISOString()}`)
        }
        if (this.filters.quoteDate) {
            filtered['quoteDate'] = DateUtil.displayLocalTime(this.filters.quoteDate, {format: Const.FORMAT_GUI_DATE_V3})
            const d = dayjs(this.filters.quoteDate).startOf('day')
            queryParams.push(`quoteTimeFrom=${d.toISOString()}`)
            queryParams.push(`quoteTimeTo=${d.add(1, 'day').toISOString()}`)
        }
        if (queryParams) {
            url = url + '?' + queryParams.join('&')
        }
        this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: filtered, 
            //   queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
        this.loading = true
        this.api.GET(url, ).subscribe((res) => {
            this.loading = false
            this.list = res
        })
    }

    onSelectClient(event) {
        if (event)
            this.filters.customer = event.id
        else
            delete this.filters.customer
        
        this.filters.offset = 0
        this.loadRequests()
    }

    onDropoffZipcodeChange(event) {
        if (this.filters.dropoffZipcode === event?.zipcode) return
        this.filters.dropoffZipcode = event?.zipcode
        this.updateFilter()
    }

    onPickupZipcodeChange(event) {
        if (this.filters.pickupZipcode === event?.zipcode) return
        this.filters.pickupZipcode = event?.zipcode
        this.updateFilter()
    }

    onChangeQuoteId(value){
        this.filters.quoteId = value;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        this.timeOut = setTimeout(() => {
            this.updateFilter();
        }, 1000);
    }


    updateFilter() {
        this.filters.offset = 0
        this.loadRequests()
    }

    onFilterChange() {
        this.loadRequests()
    }

    navigateFirst() {
        this.filters.offset = 0
        this.loadRequests()
    }

    navigatePrev() {
        this.filters.offset = this.list.skip - this.list.limit
        this.loadRequests()
    }

    navigateNext() {
        this.filters.offset = this.list.skip + this.list.limit
        this.loadRequests()
    }

    ngOnDestroy() {
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
    }

}