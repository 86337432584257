import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Observable } from "rxjs";

@Component({
  selector: 'clone-from-route-input',
  templateUrl: './view.html',
  styleUrls: [ './style.scss' ]
})
export class CloneFromRouteInput extends BaseFormDialog1 {

    public isLoading = false;

    @Input() onSave: (data) => Observable<any>;
    @Input() onOk: (resp) => void;

    protected formGroupDeclaration: FormGroupDeclaration = {
      jobCode: { label: "Route ID", required: true }
    };

    onBtnSave() {
      if (!this.needUpdate) return;
      const jobCode = this.getItemValue('jobCode');
      this.onProgress = true;
      this.onSave(jobCode).subscribe(
        resp => {
          this.onOk(resp);
          this.closeDialog();
          this.onProgress = false;
        }, err => {
          this.showErr(err);
          this.onProgress = false;
        }
      );
    }
    
  }
