<div class="box-container">
    <ng-container *ngIf="loading">
        <div class="loading-container" style="flex-direction: column;">
            <i nz-icon nzType="loading" nzTheme="outline"></i>
        </div>
    </ng-container>
      
    <div class="flex">
        <div class="flex1">
            <div *ngIf="!sessions?.length &&!loading">No active Planning session</div>
            <div *ngIf="sessions?.length">
                <h3>{{ sessions?.length }} Active Sessions</h3>
            </div>
        </div>
        <div>
            <button class="right10" nz-button (click)="onRefreshBtn()">Refresh</button>
        </div>
        <div>
            <button nz-button (click)="onAddShipmentBtn()">Add Shipments</button>
        </div>
    </div>
    <div class="clickable font-medium bottom15" (click)="toggleShowPrevSessions()">
        Previous Sessions
    </div>
    <div *ngIf="showPrevSession" class="bottom10">
        <ul>
            <li class="bottom5 clickable" *ngFor="let session of prevSessions" (click)="selectSession(session)">
                {{ session.name}}
            </li>
        </ul>
    </div>
    <div *ngFor="let day of days" class="bottom10">
        <div class="flex">
            <div class="font-medium">{{day.day}}</div>
            <div class="flex1">
            </div>
        </div>
        <div style="margin-left: -10px; margin-right: -10px;">
            <div class="card clickable" [class]="session.unrouted ? 'pending' : 'complete'" (click)="selectSession(session)" *ngFor="let session of day.sessions">
                <div class="title">
                    {{ session.name }}
                </div>
                <div class="content flex">
                    <div class="flex1">
                        <span class="right5 font-medium">{{ session.unrouted }}</span>
                        <span class="small-title">Shipments</span>
                    </div>
                    <div *ngIf="session.exportedJobCount">
                        <span class="right5 font-medium">{{ session.exportedJobCount }}</span>
                        <span class="small-title"> Routes</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="sessions?.length">
        <nz-list nzItemLayout="horizontal" [nzLoading]="loading">
            <nz-list-item *ngFor="let session of sessions" (click)="selectSession(session)" class="clickable">
                <nz-list-item-meta [nzTitle]="session.name" [nzDescription]="session.shipmentCount + ' shipments'"></nz-list-item-meta>
            </nz-list-item>
        </nz-list>
    </div> -->
</div>