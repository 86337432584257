import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { Utils } from "@services/utils";
import { ListFilterDrawer } from "../base/list-filter-drawer";
import { MasterData } from "@services/master.data";
import { TAGS } from "../shipment-entry/components/forms/order-tags/const";
import { countryListAlpha2 } from "../components/forms/form-address/country-code";
import { ZipcodeSelectorValue } from "../components/common/zipcode-selector";

type WarpIdMode = 'normal' | 'range';

@Component({
  selector: '[orders-filter]',
  templateUrl: './orders-filter.html',
  styleUrls: [
    './orders-filter.scss',
    '../../app.scss',
    '../../drawers/drawer.scss',
    '../../../styles/date-picker-row.scss',
    '../../../styles/form-v2.scss'
  ]
})
export class OrderFilter extends ListFilterDrawer {
  public listTimezones = DateUtil.listUsTimezones;

  private get declarationForSysadmin(): FormGroupDeclaration {return {
    jobId: {label: 'Job ID', notAcceptEmpty: true},
    isMultiStop: {label: '', notAcceptNull: true},
  }}

  private get declaration(): FormGroupDeclaration {return {
    warpIds: {label: 'WARP IDs', notAcceptEmpty: true},
    clientIds: {label: 'Customer', notAcceptEmpty: true},
    isIncludingSubClient: {label: 'Show results including sub customer', type: 'boolean', initialValue: true},
    carrierId: {label: 'Carrier', notAcceptEmpty: true},
    warehouseId: {label: 'Warehouse', notAcceptEmpty: true},
    sackImportID: {label: 'Sack ID', notAcceptEmpty: true},
    shipmentType: {label: 'Shipment Type', notAcceptEmpty: true},
    isCrossDock: {label: 'Using Cross Dock', notAcceptEmpty: true},
    status: {label: 'Shipment status', notAcceptEmpty: true},
    tags: {label: 'Order Tags', notAcceptEmpty: true},
    routing: {label: 'Routing status', notAcceptEmpty: true},
    requiresAppointment: {label: 'Require Appointment', notAcceptEmpty: true},
    podStatus: {label: 'POD status', notAcceptEmpty: true},
    invoiceStatus: {label: 'Invoice status', notAcceptEmpty: true},
    clientSuccessRepId: {label: 'Client Success Rep', placeHolder: 'Select', notAcceptEmpty: true},
    clientSalesRepId: {label: 'Client Sales Rep', placeHolder: 'Select', notAcceptEmpty: true},
    carrierSalesRepId: {label: 'Carrier Sales Rep', placeHolder: 'Select', notAcceptEmpty: true},
    pickupLocation: {label: 'Pickup Location', placeHolder: 'Select', notAcceptEmpty: true},
    dropoffLocation: {label: 'Delivery Location', placeHolder: 'Select', notAcceptEmpty: true},
    pickupCountry: {label: ''},
    dropoffCountry: {label: ''},
    pickupState: {label: 'From State', placeHolder: 'Select', notAcceptEmpty: true},
    dropoffState: {label: 'To State', placeHolder: 'Select', notAcceptEmpty: true},
    pickupZipcode: {label: 'From Zipcode', placeHolder: 'From Zipcode', notAcceptEmpty: true},
    dropoffZipcode: {label: 'To Zipcode', placeHolder: 'To Zipcode', notAcceptEmpty: true},
    pickupFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    pickupToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    dropoffFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    dropoffToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    actualPickupFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    actualPickupToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    actualDropoffFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    actualDropoffToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    invoiceSentFromDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'From date', formatValue: v => v, getValue: v => v},
    invoiceSentToDate: {label: '', type: 'date', notAcceptEmpty: true, placeHolder: 'To date', formatValue: v => v, getValue: v => v},
    invoiceSentById: {label: 'Invoice sent by', placeHolder: 'Select', notAcceptEmpty: true},
    pickupDateTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    dropoffDateTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    actualPickupTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
    actualDropoffTimezone: {label: 'Time zone', placeHolder: 'Time zone', notAcceptEmpty: true},
  }}

  protected formGroupDeclaration: FormGroupDeclaration = {};

  private keys = [
    'warpIds',
    'clientIds',
    'carrierId',
    'warehouseId',
    'sackImportID',
    'shipmentType',
    'routing',
    'status',
    'tags',
    'podStatus',
    'invoiceStatus',
    'clientSuccessRepId',
    'clientSalesRepId',
    'carrierSalesRepId',
    'pickupState',
    'dropoffState',
    'pickupZipcode',
    'dropoffZipcode',
    'pickupLocation',
    'dropoffLocation'
  ];

  public get formKeys(): string[] {
    if (this.isSysAdmin) {
      return [...Object.keys(this.declarationForSysadmin), ...this.keys];
    } else {
      return this.keys;
    }
  }

  warpIdMode: WarpIdMode = 'normal';
  maxNumWarpId = 200;
  get hintWarpId() {
    if (this.warpIdMode == 'range') {
      return 'Please enter the first & last WARP IDs of the range';
    } else {
      return 'Can be multiple WARP IDs';
    }
  }

  onWarpIdModeChange(mode: WarpIdMode) {
    if (this.warpIdMode == mode) {
      return;
    }
    this.warpIdMode = mode;
    this.setItemValue('warpIds', null);
    switch (mode) {
      case 'normal':
        this.formGroupDeclaration.warpIds.label = 'WARP ID';
        this.maxNumWarpId = 200;
        break;
      case 'range':
        this.formGroupDeclaration.warpIds.label = 'WARP ID (Range Mode)';
        this.maxNumWarpId = 2;
        break;
    }
  }

  getNameWarpIdMode(mode: WarpIdMode) {
    switch (mode) {
      case 'normal': return 'Normal Mode';
      case 'range': return 'Range Mode';
      default: return mode;
    }
  }

  onZipcodeChange(key, value: ZipcodeSelectorValue) {
    this.setItemValue(key, value?.zipcode)
  }

  public listStatus = Const.OrderStatusArray;
  public listTags = TAGS
  public listShipmentTypes = Const.ShipmentTypesArray;
  public listClients = [];
  public listCarriers = [];
  public listWarehouses = [];
  private fields = [
    'pickupFromDate', 'pickupToDate',
    'dropoffFromDate', 'dropoffToDate',
    'actualPickupFromDate', 'actualPickupToDate',
    'actualDropoffFromDate', 'actualDropoffToDate',
    'invoiceSentFromDate', 'invoiceSentToDate',
  ];
  private timezoneKeys = {
    pickupFromDate: 'pickupDateTimezone',
    pickupToDate: 'pickupDateTimezone',
    dropoffFromDate: 'dropoffDateTimezone',
    dropoffToDate: 'dropoffDateTimezone',
    actualPickupFromDate: 'actualPickupTimezone',
    actualPickupToDate: 'actualPickupTimezone',
    actualDropoffFromDate: 'actualDropoffTimezone',
    actualDropoffToDate: 'actualDropoffTimezone'
  };

  public countriesStates = MasterData.getCountriesStates_forSelectGroup();

  public set metadata(value) {
    if (value && value.client) {
      this.selectedClientIds = value.client;
      if (Utils.isArray(value.client)) {
        this.listClients = value.client;
      } else {
        this.listClients = [value.client];
      }
    }
    if (value && value.carrier) {
      this.selectedCarrierId = value.carrier;
      this.listCarriers = [value.carrier];
    }
    if (value && value.warehouse) {
      this.selectedWarehouseId = value.warehouse.id;
      this.listFilterWarehouses = [value.warehouse];
    }
  }

  get needUpdate(): boolean {
    return true;
  }

  get noDataWarehouse(): string {
    if (!this.selectedClientIds) return 'Please select customer first';
    return 'No data';
  }

  constructor() {
    super();
  }

  public usingCrossDockChecked = false;
  public isIncludingSubClientChecked = false;

  ngOnInit(): void {
    if (this.isSysAdmin) {
      this.formGroupDeclaration = {...this.declarationForSysadmin, ...this.declaration};
    } else {
      this.formGroupDeclaration = this.declaration;
    }
    super.ngOnInit();
    this.needAppointmentChecked = this.getItemValue('requiresAppointment');
    this.usingCrossDockChecked = this.getItemValue('isCrossDock');
    this.isIncludingSubClientChecked = this.getItemValue('isIncludingSubClient');
    this.getListWarehouse();
    this.fetchListClientSuccessRep();
    this.fetchListClientSalesRep();
    this.fetchListCarrierSalesRep();
    this.fetchListAccounting();
    this.setEnableFormGroup(true);
  }

  getApiListDataForFilter(key: string): string|undefined {
    switch (key) {
      case 'clientIds': return `${Const.APIURI_CLIENTS_FOR_FILTER}?includeSubAccount=true`;
      case 'carrierId': return Const.APIURI_CARRIERS_FOR_FILTER;
      default: return
    }
  }

  onCheckboxUsingCrossDock(value) {
    this.usingCrossDockChecked = !!value;
    this.setItemValue('isCrossDock', this.usingCrossDockChecked || null);
  }

  onCheckboxIncludingSubClient(value) {
    this.isIncludingSubClientChecked = !!value;
    this.setItemValue('isIncludingSubClient', this.isIncludingSubClientChecked || false);
  }

  protected beforeBindModel(model: any): void {
    if (model.client) {
      this.listClients = [model.client];
    }

    for(let field of this.fields) {
      if(model[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && model[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(model[timezoneKey]);
          const date = DateUtil.convertLocalTime2(model[field], timezone).toISOString();
          model[field] = new Date(date);
        } else {
          model[field] = new Date(model[field]);
        }
      }
    }

    this.mappingLocationFields(model);

    return model;
  }

  private mappingLocationFields(model: any) {
    if (model.pickupLocation?.metadata) {
      model.pickupLocation = model.pickupLocation.metadata;
    };
    if (model.pickupLocation?.state) {
      model.pickupLocation = model.pickupLocation.state;
    }
    if (model.dropoffLocation?.metadata) {
      model.dropoffLocation = model.dropoffLocation.metadata;
    };
    if (model.dropoffLocation?.state) {
      model.dropoffLocation = model.dropoffLocation.state;
    }
    this.initSelectLocationFields(model.pickupLocation, model.dropoffLocation);
  }

  protected afterBindModel(model?: any): void {
    this.updateNeedRouteCheck();
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(isCreateNew);
    for(let field of this.fields) {
      if (json[field]) {
        const timezoneKey = this.timezoneKeys[field];
        if (timezoneKey && json[timezoneKey]) {
          let timezone = DateUtil.mapTimezoneUS(json[timezoneKey]);
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          date = DateUtil.convertLocalTime(new Date(date), timezone).toISOString();
          json[field] = date;
        } else {
          let date = DateUtil.toBeginOfDay(json[field]).toISOString();
          if(field.includes("ToDate")) date = DateUtil.toEndOfDay(json[field]).toISOString();
          json[field] = date;
        }
      }
    }
    if (!json.clientIds?.length) {
      delete json.isIncludingSubClient;
    }
    return json;
  }

  protected getMetadata() {
    let client = this.listClients.filter(it => this.selectedClientIds?.includes(it._id));
    let warehouse = this.listWarehouses.filter(it => it.id == this.selectedWarehouseId)[0];
    let carrier = this.listCarriers.filter(it => it._id == this.selectedCarrierId)[0];
    return {client, warehouse, carrier};
  }

  private selectedClientIds;
  private selectedWarehouseId;
  private selectedCarrierId;

  onClientChange(clientIds) {
    this.selectedClientIds = clientIds;
  }

  onCarrierChange(carrierId) {
    this.selectedCarrierId = carrierId;
  }

  showWarehouseName(item) {
    let arr = [];
    if (item?.warpId) arr.push(item.warpId);
    if (item?.name) arr.push(item.name);
    return arr.join(" - ")
  }

  private getListWarehouse() {
    let url = `${Const.APIURI_WAREHOUSES}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listWarehouses = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }

  private getListClients() {
    let url = `${Const.APIURI_CLIENTS}/list/all_for_filter`
    this.api.GET(url).subscribe(
      resp => {
        this.listClients = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }

  onWarehouseChange(itemId) {
    this.selectedWarehouseId = itemId;
  }

  public needRouteChecked = false;

  onCheckboxNeedRoute(value) {
    if (value) {
      this.setItemValue('routing', 'not_routed');
      this.setItemValue('status', [Const.OrderStatus.needCarrier]);
    } else {
      this.setItemValue('routing', null);
      this.setItemValue('status', null);
    }
  }

  public needAppointmentChecked = false;

  onCheckboxNeedAppointment(value) {
    if (value) {
      this.setItemValue('requiresAppointment', true);
      this.needAppointmentChecked = true;
    } else {
      this.setItemValue('requiresAppointment', null);
      this.needAppointmentChecked = false;
    }
  }

  private updateNeedRouteCheck() {
    let routing = this.getItemValue('routing');
    let statuses = this.getItemValue('status');
    this.needRouteChecked = routing == 'not_routed' && Utils.isArrayNotEmpty(statuses) && statuses.includes(Const.OrderStatus.needCarrier);
  }

  onInputItemChanged(key: string, value: any) {
    switch (key) {
      case 'routing':
      case 'status':
        this.updateNeedRouteCheck();
        break;
      case 'pickupDateTimezone':
        break;
      case 'dropoffDateTimezone':
        break;
      case 'actualPickupTimezone':
        break;
      case 'actualDropoffTimezone':
        break;
      default:
        break;
    }
  }

  public listClientSuccessRep = [];
  public isFetchingClientSuccessRep = false;
  private fetchListClientSuccessRep() {
    this.isFetchingClientSuccessRep = true;
    this.api.getListClientSuccessRepUsersFilter().subscribe(
      resp => {
        this.listClientSuccessRep = resp?.data?.list_data ?? [];
        this.isFetchingClientSuccessRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingClientSuccessRep = false;
      }
    );
  }

  public listClientSalesRep = [];
  public isFetchingClientSalesRep = false;
  private fetchListClientSalesRep() {
    this.isFetchingClientSalesRep = true;
    this.api.getListClientSalesRepUsersFilter().subscribe(
      resp => {
        this.listClientSalesRep = resp?.data?.list_data ?? [];
        this.isFetchingClientSalesRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingClientSalesRep = false;
      }
    );
  }

  public listCarrierSalesRep = [];
  public isFetchingCarrierSalesRep = false;
  private fetchListCarrierSalesRep() {
    this.isFetchingCarrierSalesRep = true;
    this.api.getListAdminUsers().subscribe(
      resp => {
        this.listCarrierSalesRep = resp?.data?.list_data ?? [];
        this.isFetchingCarrierSalesRep = false;
      }, err => {
        this.showErr(err);
        this.isFetchingCarrierSalesRep = false;
      }
    );
  }

  // add search location

  public selectedCountryPick: string = 'US';
  public selectedCountryDrop: string = 'US';
  public country = countryListAlpha2;
  public listRegions = [];
  public listFilterWarehouses = [];
  public listFilterStates = [];
  public allStates = MasterData.getStatesUS();

  private getListRegions(keyword, country) { 
    let url = `${Const.APIURI_WAREHOUSES}/get/search-regions?address=${keyword}&country=${country}`;
    this.api.GET(url).subscribe(
      resp => {
        this.listRegions = resp?.data?.list_data ?? [];
      }, err => {
        Log.e(err);
      }
    )
  }
  public listAccouting = [];
  public isFetchingAccounting = false;
  private fetchListAccounting() {
    this.isFetchingAccounting = true;
    this.api.getListAccountingUsersFilter().subscribe(
      resp => {
        this.listAccouting = resp?.data?.list_data ?? [];
        this.isFetchingAccounting = false;
      }, err => {
        this.showErr(err);
        this.isFetchingAccounting = false;
      }
    );
  }

  private pickupState;
  private dropoffState;
  onPickUpStateChanged(state) {
    this.pickupState = state;
  }
  onDeliveryStateChanged(state) {
    this.dropoffState = state;
  }

  async setLocation(selectedLocation) {
    if (selectedLocation.warpId) { // location
      let url = `${Const.APIURI_WAREHOUSES}/${selectedLocation.id}`;
      const resp = await this.api.GET(url).toPromise().catch(err => {
        this.showErr(err);
      });
      if (resp) {
        var addr = resp?.data?.pickAddr;
        return {street: addr.street, city: addr.city, state: addr.state, zipcode: addr.zipcode, metadata: selectedLocation};
      }
    } else if (selectedLocation.formatedAddress) {// regions
      const terms = selectedLocation.raw.terms;
      let region = {street: '', city: '', state: '', metadata: selectedLocation};
      if (terms.length === 5 || terms.length === 4) {
        region.street = terms[1].value;
        region.city = terms[2].value;  
        region.state = terms[3].value; 
      } else if (terms.length === 3 || terms.length === 2) {
        region.city = terms[0].value;
        region.state = terms[1].value; 
      }
      Object.keys(region).forEach(key => {
        if(!region[key])
          delete region[key]
      });
      return region;
    } else if (this.allStates.find(item => item.code === selectedLocation)) {// state
      return {state: selectedLocation};
    } else {
      return selectedLocation;
    }
  }

  private timeout = null;
  onLocationSearch(key, event) {
    if (event && event.length > 1) {
      switch (key) {
        case 'pickupLocation':
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.setListFilter(event);
            this.getListRegions(event, this.selectedCountryPick);
          }, 1000);
          break;
        case 'dropoffLocation':
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.setListFilter(event);
            this.getListRegions(event, this.selectedCountryDrop);
          }, 1000);
          break;
        default:
          break;
      }
    }
  }

  setListFilter(key) {
    this.listFilterWarehouses = this.listWarehouses.filter(wh => wh.name.toLowerCase().includes(key.toLowerCase()));
    this.listFilterStates = this.allStates.filter(state => this.getStateDesc(state.code).toLowerCase().includes(key.toLowerCase()));
  }

  async onBtnSave() {
    let json: any = this.getFormData_JSON(true);
    delete json['dropoffCountry'];
    delete json['pickupCountry'];
    if (json['pickupLocation']) 
      json['pickupLocation'] = await this.setLocation(json['pickupLocation']);
    if (json['dropoffLocation']) 
      json['dropoffLocation'] = await this.setLocation(json['dropoffLocation']);
    let metadata: any = this.getMetadata();
    this.doFilter(json, metadata);
    this.onClose();
  }

  onBtnClear(): void {
    for (let key of this.formInputKeys) {
      if (this.formGroupDeclaration[key].readOnly) {
        continue;
      }
      if (key === 'pickupCountry' || key === 'dropoffCountry') this.setItemValue(key, 'US');
      else this.formInput.get(key).setValue(null);
    }
  }

  private initSelectLocationFields(initPickupLocation, initDropoffLocation) {
    if (initPickupLocation?.warpId) {
      this.listFilterWarehouses = [initPickupLocation];
    } else if (initPickupLocation?.formatedAddress) {
      this.listRegions = [initPickupLocation];
    } else if (initPickupLocation) {
      this.listFilterStates = [this.allStates.find(state => state.code === initPickupLocation)];
    }
    if (initDropoffLocation?.warpId) {
      this.listFilterWarehouses = [...this.listFilterWarehouses, initDropoffLocation];
    } else if (initDropoffLocation?.formatedAddress) {
      this.listRegions = [...this.listRegions, initDropoffLocation];
    } else if (initDropoffLocation) {
      this.listFilterStates = [...this.listFilterStates, this.allStates.find(state => state.code === initDropoffLocation)];
    }
  }


  public isSearchZipcode = {
    pickupZipcode: false,
    dropoffZipcode: false
  }
  onSearchZipcode(key: string, isSearching: boolean) {
    this.isSearchZipcode[key] = isSearching;
  }
  get isSearchingZipcode() {
    return this.isSearchZipcode.pickupZipcode || this.isSearchZipcode.dropoffZipcode;
  }

}
