import { Component, Input } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { DateUtil } from '@services/date-utils';
import { Const } from '@const/Const';
import { DialogService } from '@dialogs/dialog.service';
import { ConfirmBookingQuote } from '../confirm-shipment-detail';

@Component({
  selector: '[quote-rate]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss',]
})
export class QuoteRate extends BaseComponent {
    @Input() quote
    @Input() request
    @Input() shipment
    @Input() response

    public quotedTime
    public option
    public name
    public description
    public transitTime
    public validity
    public bookable
    public isMarkedUp: Boolean =  false
    @Input() booked
    @Input() onBookComplete: any
    public pickupDate: string = null

    seconds2duration(seconds) {
      const s = seconds % 60
      const minutes = (seconds - s) / 60
      const m = minutes % 60
      const hours = (minutes - m) / 60
      const h = hours % 24
      const d = (hours - h) / 24
      if (d > 1) return `${d} days` + (h > 0 ? ` ${h} hours` : '')
      if (d == 1) return `1 day` + (h > 0 ? ` ${h} hours` : '')
      if (h > 0) return `${h} hours ${m} minutes`
      return `${m} minutes`
    }

    ngOnChanges(): void {
        this.option = this.quote?.option
        if (this.option?.shipmentType === 'LTL' || this.option?.shipmentType === 'Volume') {
          const { carrierName, carrierCode } = this.option
          this.name = `[${carrierCode}] ${carrierName}`
        } else {
          this.name = this.option?.name
        }
        this.isMarkedUp = this.option?.rate?.items?.filter(it => it.type === 'MARKUP')?.length
        this.description = ((this.option?.serviceLevel ?? '') + ' ' + (this.option?.description ?? '')).trim()
        this.transitTime = this.option?.transitTime ? this.seconds2duration(this.option.transitTime) : null
        
        const { completedTs } = this.quote?.quote || {}
        this.quotedTime = completedTs ? DateUtil.dateToString(new Date(completedTs), Const.FORMAT_GUI_DATETIME_V5) : null

        this.bookable = this.option?.bookable && (!this.option?.expirationTs || this.option.expirationTs > Date.now()) && this.request?.status !== 'BOOKED'
        this.validity = this.option?.expirationTs ? DateUtil.dateToString(new Date(this.option?.expirationTs), Const.FORMAT_GUI_DATETIME_V5) : null

        if (this.option?.windows?.length) {
          this.pickupDate = DateUtil.dateToString(new Date(this.option?.windows[0].from), Const.FORMAT_GUI_DATE)
        }
        if (this.option.rate.items)
        for (let r of this.option.rate.items) {
          if (!r.type) {
            r.type = 'BASE'
          }
        }
    }

    onClickBookLoadBtn() {
      DialogService.openDialog(ConfirmBookingQuote, {
        // nzWidth: this.routers?.length > 0 ? 960 : 480,
        nzWidth: 480,
        nzComponentParams: {
          rateOption: this.quote,
          request: this.request,
          shipment: this.shipment,
          response: this.response,
          // routers: this.routers,
          // shipment: this.filters,
          // deliveryDate: this.deliveryDate,
          onConfirmShipmentDetail: () => this.onConfirmShipmentDetail(),
          onBookComplete: (id) => this.onBookComplete && this.onBookComplete(id)
        },
        nzClassName: 'modal-no-padding modal-service-option',
      });
    }

    onConfirmShipmentDetail() {
      this.router.navigate([Const.routeAdminFreightQuoteFtl, this.option.requestId, 'book', this.option.id ]);
    }
}