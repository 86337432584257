<div class="dashboard-child-container no-padding list">
  <div class="list-header">
    <div carrier-pool-filter></div>
    <div class="flex2"></div>
    <div class="group-btn">
      <button *ngIf="requirePermissions([PermissionCode.pool.create])" nz-button (click)="onBtnCreate()" style="height: 32px;">
        <i nz-icon nzType="plus" nzTheme="outline"></i> Create Carrier Pools
      </button>
      <button nz-button nzSize="small" (click)="onBtnExport()" [nzLoading]="isExporting" style="height: 32px;">
        <i nz-icon nzType="file-excel" nzTheme="outline"></i> Export
      </button>
    </div>
  </div>
  <div class="list-body">
    <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzTotal]="totalCount"
      [nzFrontPagination]="false" [nzShowPagination]="totalCount > listData.length" [nzPageSize]="limit" [nzPageIndex]="pageIndex"
      [nzLoading]="isLoading" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData" [nzSize]="'default'"
      (nzPageIndexChange)="onDataListPageChanged($event)">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead (nzSortOrderChange)="onSortOrderChange($event)">
        <tr>
          <th [nzSortOrder]="sortOrder['name']" [nzSortFn]="true" nzColumnKey="name" >Pool Name</th>
          <th [nzSortOrder]="sortOrder['description']" [nzSortFn]="true" nzColumnKey="description">Description</th>
          <th [nzSortOrder]="sortOrder['countCarrier']" [nzSortFn]="true" nzColumnKey="countCarrier">Number of Carrier</th>
          <th nzColumnKey="count">Pool Type</th>
          <th nzColumnKey="count">Equipments</th>
          <th nzColumnKey="count">Carrier Sale Rep</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of listData; let i = index">
          <td>
            <a [routerLink]="[routeAdminPools, item.id]">{{item.name || 'N/A'}}</a>
          </td>
          <td>
            {{item.description}}
          </td>
          <td>
            {{item.subjectIds?.length}}
          </td>
          <td>
            <nz-tag *ngIf="item.autoAddCarrier" nzColor="processing">Auto</nz-tag>
            <nz-tag *ngIf="item.isDedicatedPool" nzColor="success">Dedicated</nz-tag>
            <nz-tag *ngIf="!item.autoAddCarrier && !item.isDedicatedPool" nzColor="default">Normal</nz-tag>
          </td>
          <td>
            {{getEquipments(item)}}
          </td>
          <td>{{ getCarrierSaleRep(item) }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>