import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BaseComponent } from '@abstract/BaseComponent';
import { MasterData } from '@services/master.data';
import { ulid } from 'ulid';

@Component({
  selector: '[freight-quote-ftl-search-form]',
  templateUrl: './index.html',
  styleUrls: ['../index.scss', './style.scss']
})
export class FreightQuoteFTLSearchForm extends BaseComponent {
    public dropoffZipcode: string | null = null
    public pickupZipcode: string | null = null
    public vehicleType: string | null = null
    @Input() isLoading: boolean = false
    @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();
    public invalid: boolean = true
    clientId: any = null
    clients = [];
    itemTypes = ['Pallet']
    pickupDate: any = null
    shipmentType = 'FTL'
    items = []
    providers: string | null = null

    pickupOptions: any[] = MasterData.ShipmentServiceOptionsPickup.map(it => Object.assign({}, it))
    deliveryOptions: any[] = MasterData.ShipmentServiceOptionsDelivery.map(it => Object.assign({}, it))
  
    constructor() {
        super();
    }
    
    ngOnInit(): void {
    }

    validateInput() {
        if (!this.dropoffZipcode || !this.pickupZipcode) {
            this.invalid = true
            return
        }
        if (this.shipmentType === 'FTL') {
            if (!this.vehicleType) {
                this.invalid = true
                return
            }
            this.invalid = false
            return
        }
        if (this.shipmentType === 'LTL') {
            if (!this.clientId) {
                this.invalid = true
                return
            }
            if (!this.pickupDate) {
                this.invalid = true
                return
            }
            if (!this.items || this.items.length < 1) {
                this.invalid = true
                return
            }
            for (let item of this.items) {
                if (!item.weight || !item.height || !item.length || !item.weight) {
                    this.invalid = true
                    return
                }
            }
        }
        this.invalid = false
    }

    onPickupZipcodeChange(e) {
        this.pickupZipcode = e
        this.validateInput()
    }
    onDropoffZipcodeChange(e) {
        this.dropoffZipcode = e
        this.validateInput()
    }
    onVehicleTypeChange(e) {
        this.vehicleType = e.code
        this.validateInput()
    }

    onBtnSearch() {
        const items = this.items.map(it => {
            const freightClass = it.freightClass?.trim()
            if (freightClass != null && freightClass != undefined && freightClass.length < 1)
                delete it.freightClass
            return it
        })
        this.onSearch?.emit({
            dropoff: this.dropoffZipcode,
            pickup: this.pickupZipcode,
            vehicleType: this.vehicleType,
            clientId: this.clientId,
            shipmentType: this.shipmentType,
            items: this.items,
            pickupDate: this.pickupDate,
            pickupOptions: this.pickupOptions.filter(it => it.isSelected).map(it => it._id),
            deliveryOptions: this.deliveryOptions.filter(it => it.isSelected).map(it => it._id),
            providers: this.providers
        })
    }

    onSelectClient(event) {
        this.clientId = event.id
        this.validateInput()
    }

    onSelectPickupDate(event) {
        event.setHours(7, 0, 0, 0)
        console.log(event)
        this.pickupDate = event
        this.validateInput()
    }

    onSerivceOptionsBtnDone(event, type) {
        // dont do anything
    }

    onAddNewItem(type) {
        let item = {
            id: ulid(),
            packaging: type,
            quantity: 1
        }
        if (type === 'Pallet') {
            item['length'] = 40
            item['width'] = 48
            item['height'] = 50
        }
        this.items.push(item)
    }

    onDeleteItem(id) {
        this.items = this.items.filter(it => it.id !== id)
        this.validateInput()
    }
    onUpdateItem(event) {
        this.validateInput()
    }

    changeShipmentType(t) {
        if (this.shipmentType === t) return
        this.shipmentType = t
        // reset
        if (t === 'LTL') {
            this.vehicleType = null
        } else {
            this.pickupDate = null
            this.pickupOptions = MasterData.ShipmentServiceOptionsPickup.map(it => Object.assign({}, it))
            this.deliveryOptions = MasterData.ShipmentServiceOptionsDelivery.map(it => Object.assign({}, it))
            this.items = []
        }
    }

    ltlProviders = [
        {
            label: 'All',
            value: ''
        },
        {
            label: 'Warp',
            value: 'WARP'
        },
        {
            label: 'Priority1',
            value: 'Priority1'
        },
        {
            label: 'WWEX',
            value: 'WWEX'
        }
    ]
}