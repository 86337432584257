<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>


<nz-transfer *ngIf="!isLoading" [nzDataSource]="list" [nzDisabled]="isDisabled" [nzShowSearch]="true"
  [nzFilterOption]="filterCarrier" [nzShowSelectAll]="false" [nzRenderList]="[renderList, renderList]"
  [nzItemUnit]="'carrier'" [nzItemsUnit]="'carriers'" (nzChange)="change($event)" 
  nzSearchPlaceholder="Search by Carrier Name, MC Number or DOT Number" >
  <ng-template #renderList let-items let-direction="direction" let-stat="stat" let-disabled="disabled"
    let-onItemSelectAll="onItemSelectAll" let-onItemSelect="onItemSelect">
    <nz-table #t [nzData]="$asTransferItems(items)" nzSize="small" [nzHideOnSinglePage]="true">
      <thead>
        <tr>
          <th [nzDisabled]="disabled" [nzChecked]="stat.checkAll" [nzIndeterminate]="stat.checkHalf"
            (nzCheckedChange)="onItemSelectAll($event)"></th>
          <th>Carrier Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of t.data" (click)="onItemSelect(data)">
          <td [nzChecked]="!!data.checked" [nzDisabled]="disabled || data.disabled"
            (nzCheckedChange)="onItemSelect(data)"></td>
          <td>
            {{ data.title }}
            <a [routerLink]="routerLinkCarrierDetail(data.key)" target="_blank" class="left5 link" (click)="$event.stopPropagation();">
              <i nz-icon nzType="link" nzTheme="outline"></i>
            </a>
          </td>
          <td>
            <ng-container *ngIf="data.status != 1">
              <nz-tag nzColor="warning">{{$asCarrierStatusText(data.status)}}</nz-tag>
            </ng-container>
            <ng-container *ngIf="data.status == 1">
              <nz-tag nzColor="success">Active</nz-tag>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
</nz-transfer>
