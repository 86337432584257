import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { LoadingComponent } from '@app/admin/components/loading/loading.component';
import { Const } from '@const/Const';
import { BaseFormDialog1 } from '@dialogs/base-form-dlg1';
import { DialogService } from '@dialogs/dialog.service';
import { DateUtil } from '@services/date-utils';
import { Utils } from '@services/utils';
import { ZipcodeService } from '@services/zipcode.service';
import { ulid } from 'ulid';
import { CloneFromRouteInput } from '../clone-from-route';
import { ZipcodeSelectorValue } from '@app/admin/components/common/zipcode-selector';

@Component({
  selector: '[create-manual-load-container]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss', '../../../dialogs/dialogs.scss']
})
export class CreateManualLoadContainer extends BaseFormDialog1 {

  public canAddMultiStop = false;
  public isLoading: boolean = false;
  @Input() onSubmitSucceeded: (resp) => void;
  zipcodeService;
  items = [];
  public vehicleTypeValue;
  public shouldSetHoursDefaul: boolean = true;

  constructor() {
    super();
    this.zipcodeService = new ZipcodeService(this.api)
  }

  protected formGroupDeclaration: FormGroupDeclaration = {
    requiredVehicle: { label: 'Vehicle', notAcceptEmpty: true, placeHolder: 'Select' },
    addressType: { label: 'Address Type', initialValue: 'zipcode'},
    pickInfos: { label: 'Pickup Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
    dropInfos: { label: 'Delivery Informations', type: 'formArray', initialValue: [{}], childItem: {
      zipcode: { label: 'Pickup zipcode'},
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
    stops: { label: 'Stop Informations', type: 'formArray', initialValue: [], childItem: {
      type: {label: 'Type' },
      address: {label: 'Address' },
      rangeDate: { label: 'Pickup date'},
    }},
  }

  public listAddressTypes = [
    { value: 'zipcode', label: 'Zipcode' },
    { value: 'fullAddress', label: 'Full Address' },
  ]

  onBtnClose() {
    this.closeDialog();
  }

  onVehicleTypeChange(e) {
    this.setItemValue('requiredVehicle', e);
  }

  get isFullAdrressSelected() {
    return this.getItemValue('addressType') === 'fullAddress';
  }

  getLabelAddressType(groupKey: 'pickInfos'|'dropInfos') {
    let addressType = this.isFullAdrressSelected ? 'Address' : 'zipcode';
    let taskType = groupKey === 'pickInfos' ? 'Pickup' : 'Delivery';
    return `${taskType} ${addressType}`;
  }

  onBtnAddStop(groupKey: 'pickInfos'|'dropInfos') {
    if (!this.canAddMultiStop) {
      return;
    }
    this.addItemToFormArray(groupKey);
    this.formInput.get(groupKey).updateValueAndValidity();
  }

  shouldShowRemoveStop(groupKey: 'pickInfos'|'dropInfos') {
    if (!this.formInput) return false;
    let faInfos = <FormArray>this.formInput.get(groupKey);
    return faInfos.length > 1;
  }

  onBtnRemoveStop(groupKey: 'pickInfos'|'dropInfos', index: number) {
    let fa = this.getFormArray(groupKey);
    fa.removeAt(index);
  }

  onZipcodeChange(groupKey: 'pickInfos'|'dropInfos', index: number, e: ZipcodeSelectorValue) {
    this.setItemValue(`${groupKey}[${index}].zipcode`, e);
    if (e?.zipcode) {
      this.zipcodeService.getTimeZoneByZipcode(e.zipcode, resp => {
        if (resp) {
          let newData = { ...e, timeZoneStandard: resp }
          this.setItemValue(`${groupKey}[${index}].zipcode`, newData);
        }
      }, err => {
      })
    }
  }

  getStopAddress(index: number) {
    const addr = this.getItemValue(`stops[${index}].address`);
    const type = this.getItemValue(`stops[${index}].type`);
    return `${type} at ${this.getAddressText(addr)}`;
  }

  protected getFormData_JSON(isCreateNew: boolean): object {
    let json: any = super.getFormData_JSON(true); // always get full data
    let dataCustom: any = {};
    dataCustom.requiredVehicle = json.requiredVehicle;
    dataCustom.deliveryInfos = [];
    if (this.isCloningFromLoad) {
      let infos = json['stops'];
      for (let item of infos || []){
        let deliveryInfo: any = {};
        deliveryInfo.type = item.type;
        deliveryInfo.addr = item.address;

        if (!item.rangeDate) {
          deliveryInfo.windows = [{ from: null, to: null }];
        } else if (Utils.isArrayNotEmpty(item.rangeDate)) {
          let fromPickDate = item.rangeDate[0];
          let toPickDate = item.rangeDate[item.rangeDate.length - 1];
          let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
          fromPickDate = DateUtil.convertLocalTime(fromPickDate, timezone)?.toISOString();
          toPickDate = DateUtil.convertLocalTime(toPickDate, timezone)?.toISOString();
          deliveryInfo.windows = [{
            from: fromPickDate,
            to: toPickDate
          }]
        }
        dataCustom.deliveryInfos.push(deliveryInfo);
      }
    } else {
      for (let groupKey of ['pickInfos', 'dropInfos']) {
        let infos = json[groupKey];
        for (let item of infos || []){
          let deliveryInfo: any = {};
          deliveryInfo.type = groupKey === 'pickInfos' ? Const.TaskType.PICKUP : Const.TaskType.DROPOFF;
  
          if (this.isFullAdrressSelected) {
            deliveryInfo.addr = item.address;
          } else {
            deliveryInfo.addr = {
              zipcode: item.zipcode.zipcode,
              city: item.zipcode.city,
              state: item.zipcode.state,
              location: {
                latitude: item.zipcode.latitude,
                longitude: item.zipcode.longitude,
              },
              metadata: {
                timeZoneStandard: item.zipcode.timeZoneStandard,
                latitude: item.zipcode.latitude,
                longitude: item.zipcode.longitude,
              },
            }
          }
  
          if (!item.rangeDate) {
            deliveryInfo.windows = [{ from: null, to: null }];
          } else if (Utils.isArrayNotEmpty(item.rangeDate)) {
            let fromPickDate = item.rangeDate[0];
            let toPickDate = item.rangeDate[item.rangeDate.length - 1];
            if (this.shouldSetHoursDefaul) {
              //FIXME lay time 7:00AM theo timezone
              fromPickDate = item.rangeDate[0]?.setHours(7, 0, 0, 0);
              toPickDate = item.rangeDate[item.rangeDate.length - 1]?.setHours(7, 0, 0, 0);
            }
            let timezone = deliveryInfo.addr?.metadata?.timeZoneStandard;
            fromPickDate = DateUtil.convertLocalTime(fromPickDate, timezone)?.toISOString();
            toPickDate = DateUtil.convertLocalTime(toPickDate, timezone)?.toISOString();
            deliveryInfo.windows = [{
              from: fromPickDate,
              to: toPickDate
            }]
          }
          dataCustom.deliveryInfos.push(deliveryInfo);
        }
      }
    }
    
    return dataCustom;
  }

  private get canSubmitForm(): boolean {
    if (!this.isCloningFromLoad) {
      for (let groupKey of ['pickInfos', 'dropInfos']) {
        let fa = <FormArray>this.formInput.get(groupKey);
        for (let i = 0; i < fa.length; i++) {
          let fg = fa.at(i);
          let addressTypeKey = this.isFullAdrressSelected ? 'address' : 'zipcode';
          let value = fg?.get(addressTypeKey)?.value;
          if (!value) {
            this.showErr(`Please enter ${addressTypeKey} valid.`)
            return false;
          }
        }
      }
      let fcDate = (<FormArray>this.formInput?.get('pickInfos'))?.at(0)?.get('rangeDate');
      if (!fcDate || !fcDate.value) {
        this.showErr('Please select pickup date.')
        return false;
      }
    }
    for (let item of this.items) {
      if (!item.weight || !item.height || !item.length || !item.weight) {
        this.showErr('Shipping Item is required.')
        return false;
      }
    }
    return true;
  }

  loadingRef;
  onBtnCreateLoad() {
    if (!this.canSubmitForm) return false;
    const payload: any = this.getFormData_JSON(true);
    if (this.items?.length) {
      payload.items = this.items;
    }
    this.isLoading = true;
    this.loadingRef = DialogService.openFormDialog1(LoadingComponent, {});
    this.api.POST(Const.APIV2(`${Const.APIURI_JOBS}/manual-load`), payload).subscribe(
      (resp) => {
        this.isLoading = false;
        this.showSuccess('Load has been created successfully.');
        this.loadingRef?.close();
        this.onSubmitRequestSucceeded(resp);
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
        this.loadingRef?.close();
      }
    );
  }

  onSubmitRequestSucceeded(resp) {
    this.onSubmitSucceeded(resp);
    this.closeDialog();
  }

  onAddNewItem(type) {
    let item = {
        id: ulid(),
        packaging: type,
        quantity: 1
    }
    if (type === 'Pallet') {
        item['length'] = 40
        item['width'] = 48
        item['height'] = 50
    }
    this.items.push(item)
  }

  onDeleteItem(id) {
    this.items = this.items.filter(it => it.id !== id)
  }

  onBtnCloneFromRoute() {
    DialogService.openFormDialog1(CloneFromRouteInput, {
      nzComponentParams: {
        onSave: (jobCode) => {
          const url = `${Const.APIV2(Const.APIURI_JOBS)}/get-detail-by-code/${jobCode}`;
          return this.api.GET(url);
        },
        onOk: (resp) => {
          this.bindingDataRouteToForm(resp);
        }
      },
      nzClassName: "modal",
    });
  }

  public isCloningFromLoad = false;
  private bindingDataRouteToForm(resp) {
    if (!resp?.data?.id) return;
    // fixme  tam thoi
    const { requiredVehicle, stops, metadata } = resp.data;
    // if (stops.length && stops.length != 2) {
    //   this.showErr('Not support number of stops more than 2');
    //   return
    // }
    if (requiredVehicle?.code && requiredVehicle?.name) {
      this.vehicleTypeValue = requiredVehicle;
      this.onVehicleTypeChange(this.vehicleTypeValue);
    }
    this.setItemValue('addressType', 'fullAddress');
    // for (let i=0; i< stops.length; i++) {
    //   const stop = stops[i];
    //   const addr = stop?.info?.addr;
    //   if (stop.type === Const.TaskType.PICKUP && addr) {
    //     this.setItemValue(`pickInfos[0].address`, addr);
    //     const timeWindow = this.getAppointment(stop.info);
    //     const pickupTimeZone = addr.metadata?.timeZoneStandard;
    //     if (timeWindow?.from && pickupTimeZone) {
    //       const fromTime = DateUtil.convertLocalTime2(timeWindow.from, pickupTimeZone);
    //       const toTime = DateUtil.convertLocalTime2(timeWindow.to, pickupTimeZone);
    //       if (fromTime && toTime) {
    //         this.setItemValue(`pickInfos[0].rangeDate`, [fromTime, toTime]);
    //         this.shouldSetHoursDefaul = false;
    //       }
    //     }
    //   }
    //   if (stop.type === Const.TaskType.DROPOFF && addr) {
    //     this.setItemValue(`dropInfos[0].address`, addr);
    //     const timeWindow = this.getAppointment(stop.info);
    //     const dropTimeZone = addr.metadata?.timeZoneStandard;
    //     if (timeWindow?.from && dropTimeZone) {
    //       const fromTime = DateUtil.convertLocalTime2(timeWindow.from, dropTimeZone);
    //       const toTime = DateUtil.convertLocalTime2(timeWindow.to, dropTimeZone);
    //       if (fromTime && toTime) {
    //         this.setItemValue(`dropInfos[0].rangeDate`, [fromTime, toTime]);
    //         this.shouldSetHoursDefaul = false;
    //       }
    //     }
    //   }
    // }
    for (let i=0; i< stops.length; i++) {
      this.addItemToFormArray('stops');
      this.formInput.get('stops').updateValueAndValidity();
      const stop = stops[i];
      const addr = stop?.info?.addr;
      this.setItemValue(`stops[${i}].type`, stop.type);
      if (addr) {
        this.setItemValue(`stops[${i}].address`, addr);
        const timeWindow = this.getAppointment(stop.info);
        const stopTimeZone = addr.metadata?.timeZoneStandard;
        if (timeWindow?.from && stopTimeZone) {
          const fromTime = DateUtil.convertLocalTime2(timeWindow.from, stopTimeZone);
          const toTime = DateUtil.convertLocalTime2(timeWindow.to, stopTimeZone);
          if (fromTime && toTime) {
            this.setItemValue(`stops[${i}].rangeDate`, [fromTime, toTime]);
          }
        }
      }
    }
    if (metadata?.shipmentItems?.length) {
      let items: any[] = [];
      for (let item of metadata.shipmentItems) {
        if (item.qtyUnit != "Pallet") continue;
        items.push({
          id: ulid(),
          packaging: "Pallet",
          quantity: item.qty ?? 1,
          length: item.length ?? 40,
          width: item.width ?? 48,
          height: item.height ?? 50,
          weight: item.weightPerUnit,
        });
      }
      this.items = items;
    }
    this.isCloningFromLoad = true;
  }

  private getAppointment(info) {
    const appointment = info.appointmentInfo;
    if (appointment?.from) return appointment

    const timeWindow = info?.windows?.[0];
    if (timeWindow?.from) return timeWindow;
  }

}