<div>
    <div class="flex">
        <div class="flex1">
            <span class="button" (click)="back()"><i nz-icon nzType="left"></i> Back</span>
        </div>
        <div>
            <a [routerLink]="[routeAdminFreightQuoteFtl]">
                <span class="button right">New Search</span>
            </a>
            <nz-divider nzType="vertical"></nz-divider>
            <a [routerLink]="[routeAdminFreightQuoteFtl, 'history']">
                <span class="button">History <i nz-icon nzType="right"></i></span>
            </a>
        </div>
    </div>
    <div nz-row [nzGutter]="{md:32}">
        <div nz-col *ngIf="origin">
            <div class="label">Origin</div>
            <div>
                <span class="emphasized">{{ origin.zipcode }}</span>
                <span *ngIf="origin.city"> ({{origin.city}}, {{origin.state}})</span>
            </div>
        </div>
        <div nz-col *ngIf="destination">
            <div class="label">Destination</div>
            <div>
                <span class="emphasized">{{ destination.zipcode }}</span>
                <span *ngIf="destination.city"> ({{destination.city}}, {{destination.state}})</span>
            </div>
        </div>
        <div nz-col *ngIf="vehicleType">
            <div class="label">Vehicle Type</div>
            <div class="emphasized">{{ vehicleType }}</div>
        </div>
        <div nz-col *ngIf="pickupDate">
            <div class="label">Pickup Date</div>
            <div class="emphasized">{{ pickupDate }}</div>
        </div>
        <div nz-col *ngIf="serviceOptions.length">
            <div class="label">Service Options</div>
            <div *ngFor="let opt of serviceOptions">{{ opt }}</div>
        </div>
        <div nz-col *ngIf="quoteRequest.items?.length">
            <div class="label">Items</div>
            <div *ngFor="let item of quoteRequest.items">
                <div class="bottom3">
                    <span class="right3">{{ item.quantity }}</span> <span>{{ item.packaging ?? 'Pallet(s)' }} </span>
                    <span>{{ item.length ?? '' }}x{{ item.width ?? '' }}x{{ item.height ?? '' }}</span>
                    <span class="right3">{{ item.sizeUnit ?? 'IN' }}</span>
                    <span>, {{ item.totalWeight ?? (item.weight * item.quantity) }} {{ item.weightUnit ?? 'lb' }}</span>
                    <span *ngIf="item.freightClass">, class: {{ item.freightClass}}</span>
                </div>
                <div class="bottom10 description">{{ item.description }}</div>
            </div>
        </div>
    </div>
    <div>
        <div freight-quote-result [request]="quoteRequest" [response]="quoteResponse"></div>
    </div>
</div>