import { Component, Input } from '@angular/core';
import { Const } from '@const/Const';
import { BaseDialog } from '@dialogs/base-dlg';
import { DateUtil } from '@services/date-utils';
import { InputHelper } from '@services/input-helper';
import { AddressUS } from '@wearewarp/types/data-model';

@Component({
  selector: '[confirm-shipment-before-book]',
  templateUrl: './index.html',
  styleUrls: ['./index.less' ]
})
export class ConfirmShipmentBeforeBook extends BaseDialog {
  @Input() shipment: any;
  @Input() filters: any;
  @Input() quote: any;
  @Input() onClose: any;
  @Input() logId: string;

  oldDeliveryDate = '';
  newDeliveryDate = '';
  newFromTime;
  newToTime;
  pickDate = '';
  isLoading = false;
  cost;

  constructor() {
    super();
  }
  
  ngOnInit(): void {
    this.getPickDate();
    this.getShipmentDeliveryDate();
    this.getExfreightDeliveryDate();
  }
  get isDisabled() {
    return (!this.newDeliveryDate || !this.newFromTime || !this.newToTime || !this.cost)
  }
  onBtnConfirmAndContinue(isReplaceData: boolean) {
    let replaceData = null;
    let newDate = DateUtil.toTimeWindow(this.newDeliveryDate, this.newFromTime, this.newToTime, this.dropoffInfo?.addr?.metadata?.timeZoneStandard);
    if (isReplaceData) {
      replaceData = {
        windows:  [newDate],
      }
      if (this.cost == 'new') {
        replaceData.cost = this.newCost
      }
    }
    const params = {
      replaceData,
      thirdpartyInfo: {
        orderId: this.shipment.orderId,
        info: {
          route_number: this.quote?.route_number,
          total_charge: this.quote?.total_charge,
          legs: this.quote?.legs,
          rated_items: this.quote?.exfreightRes?.exfreightRatedItems,
          reference_key: this.quote?.exfreightRes?.reference_key,
          logId: this.logId
        },
        source: Const.thirdPartyOrderSource.Exfreight,
      }
    }
    this.isLoading = true;
    let url = `v2/${Const.APIURI_SHIPMENTS}/${this.shipment.id}/book-exfreight`;
    this.api.POST(url, params).subscribe(
      (response) => {
        this.isLoading = false;
        this.closeDialog();
        this.onClose();
        this.showSuccess(`Shipment ${this.showShipmentWarpId(this.shipment)} has been booked with Exfreight`)
      },
      (err) => {
        this.isLoading = false;
        this.showErr(err);
      }
    );
  }

  get pickInfo() {
    return this.shipment.deliveryInfos.find(info => info.type == Const.TaskType.PICKUP);
  }
  
  get dropoffInfo() {
    return this.shipment.deliveryInfos.find(info => info.type == Const.TaskType.DROPOFF);
  }
  get newCost() {
    let costTemplate = {
      currency: {
        type: 'USD',
        fxRate: null
      },
      transitCost : {
          rate : 0,
          qty : 1,
          total : 0
      },
      volumeDiscount : {
          type : "percentage",
          percentage : null,
          flatRate : null,
          qty : null,
          total : 0
      },
      subTotal : 0,
      fuelCost : {
          type : "rpm",
          percentage : null,
          rpm : null,
          qty : null,
          total : 0
      },
      serviceOptions : [],
      grandTotal : 0
    }
    let costValue = this.quote?.total_charge?.value ?? 0;
    if (this.quote?.total_charge?.unit == Const.MonenyUnits.us_cent) {
      costValue = Const.convertCentToDollar(costValue);
    }
    costTemplate.grandTotal = costValue;
    let subTotal = costValue;
    if (this.quote?.fuelCost?.total) {
      costTemplate.fuelCost = this.quote?.fuelCost;
      subTotal = costValue - this.quote.fuelCost.total;
    }
    costTemplate.transitCost.rate = subTotal;
    costTemplate.transitCost.total = subTotal;
    costTemplate.subTotal = subTotal;
    return costTemplate;
  }
  get oldCost() {
    return this.shipment?.cost?.grandTotal ?? 0;
  }

  formatMoney(cost) {
    return InputHelper.formatMoney2(cost);
  }

  getPickDate() {
    let pickDate = '';
    const objectPickupDate = this.pickInfo?.windows?.[0] ?? this.pickInfo?.appointmentInfo;
    if (objectPickupDate) {
      pickDate = DateUtil.displayTimeWindow(objectPickupDate, {
        timezone: this.pickInfo?.addr?.metadata?.timeZoneStandard,
        formatDateOnly: 'MM/DD',
        format: Const.FORMAT_GUI_DATETIME_V3,
      })
    }
    this.pickDate = pickDate;
  }

  getExfreightDeliveryDate() {
    if (this.quote.legs.length) {
      this.newDeliveryDate = DateUtil.format(this.quote.legs[this.quote.legs.length - 1]?.scheduled_delivery_date, Const.FORMAT_GUI_DATE_V3);
    }
  }

  getShipmentDeliveryDate() {
    let shipmentDeliveryDate = '';
    const dropoffDate = this.dropoffInfo?.windows?.[0] ?? this.dropoffInfo?.appointmentInfo;
    if (dropoffDate) {
      shipmentDeliveryDate =  DateUtil.displayTimeWindow(dropoffDate, {
        timezone: this.dropoffInfo?.addr?.metadata?.timeZoneStandard,
        formatDateOnly: 'MM/DD',
        format: Const.FORMAT_GUI_DATETIME_V3,
      })
    }
    this.oldDeliveryDate = shipmentDeliveryDate ?? 'N/A';
  }

  // Địa chỉ mới chỉ có zipcode, city, state
  getAddressText(addr: AddressUS): string {
    if (!addr) return '';
    let street = addr.street;
    if (addr.street2) {
      street += `, ${addr.street2}`;
    }
    let city = `${addr.city}, ${addr.state}, ${addr.zipcode}`;
    if (street) {
      return `${street}, ${city}`;
    } else {
      return city;
    }
  }
}