import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormAddressUSModule } from '../base/form-address-us/module';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { DetailModule } from '../base/detail.module';
import { SharedModule } from '../shared/shared.module';
import { CreateManualLoadContainer } from './create-load';
import { UiCommonModule } from '../components/common/module';
import { QuoteItem } from './quote-item';
import { CloneFromRouteInput } from './clone-from-route';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzPaginationModule,
    NzIconModule,
    NzGridModule,
    NzEmptyModule,
    NzInputModule,
    NzFormModule,
    NzDatePickerModule,
    NzStepsModule,
    NzTimePickerModule,
    NzAutocompleteModule,
    NzTagModule,
    NzCollapseModule,
    SearchBoxModule,
    FormAddressUSModule,
    FormInputCostModule,
    NzTimelineModule,
    NzDropDownModule,
    SelectBySearchingModule,
    RouterModule,
    NzToolTipModule,
    NzIconModule,
    NzCardModule,
    NzSwitchModule,
    NzCalendarModule,
    DetailModule,
    SharedModule,
    UiCommonModule,
  ],
  declarations: [
    CreateManualLoadContainer,
    QuoteItem,
    CloneFromRouteInput,
  ],
  exports: [
  ],
})
export class ManualLoadModule { }
