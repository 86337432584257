<div class="box-container">
    <div class="flex outer-box">
        <div class="stop-list-container">
            <div class="stop-list-header">
                <input nz-input
                placeholder="shipment Id"
                [(ngModel)]="additionalShipmentIds"
                (keypress)="onInputKeyPress($event)" >
            </div>
            <div class="stop-list" *ngIf="route && route.stops" route-order [route]="route" #routeOrder
              (onChangeOrder)="changeOrder($event)" (onHover)="onMouseOverStop($event)"></div>
            <div *ngIf="loadingShipment" class="loading">
                <i nz-icon nzType="loading" nzTheme="outline"></i>
            </div>
            <div class="bottom-control-panel" *ngIf="route?.cost">
                <div class="total-route-cost">
                    Total Cost: {{ (route.cost.distance / 1609.34).toFixed(1) }} mi, {{ (route.cost.time / 3600).toFixed(1) }} h
                </div>
                <div class="bottom10">
                    <button *ngIf="!selectingDriverLocation" nzType="primary" class="right3" nzSize="small" nz-button (click)="optimize()">Optimize</button>
                    <button *ngIf="!selectingDriverLocation" nzSize="small" nz-button (click)="onSelectDriverLocation()">Driver Location</button>
                    <span *ngIf="selectingDriverLocation">Select driver location on map</span>
                </div>
                <div>
                    <button *ngIf="!selectingDriverLocation && route && route.stops?.length" nzType="primary" class="right3" nzSize="small" nz-button (click)="onConfirmingExport(true)">Export</button>
                    <button *ngIf="!selectingDriverLocation && route && route.stops?.length" nzDanger class="right3" nzSize="small" nz-button (click)="clear()">Clear</button>
                </div>
                <div class="top10">
                    <button *ngIf="canCreateExternalRoute" nzType="primary" class="right3" nzSize="small" nz-button (click)="onBtnCreateExternalRoute()">Create External Route</button>
                </div>
            </div>
        </div>
        <div class="flex1 map">
            <div class="map-container">
                <div #mapboxContainer></div>
                <canvas #deckCanvas></canvas>    
            </div>            
        </div>
    </div>
    <div *ngIf="exporting" class="notification-modal">
        <div class="loading-box">
            <div class="bottom20">Exporting Route ...</div>
            <div>
                <i nz-icon nzType="loading" nzTheme="outline"></i>
            </div>
        </div>
    </div>
    <div *ngIf="confirmingExport && !exportedJob" class="notification-modal">
        <div class="confirmation-box">
            <div class="bottom20">Please confirm you want to export the route.</div>
            <div>
                <button class="right5" nz-button nzType="primary" (click)="export()">Confirm</button>
                <button nz-button nzDanger (click)="onConfirmingExport(false)">Cancel</button>
            </div>
        </div>
    </div>
    <div *ngIf="exportedJob" class="notification-modal">
        <div class="confirmation-box">
            <div class="bottom20">
                Route {{ exportedJob.code }} has been created.
            </div>
            <div class="bottom20">
                <a target="_blank" [routerLink]="[routeAdminDispatchList, exportedJob.id]">View Route</a>
            </div>
            <div>
                <button style="width: 120px;" nz-button nzType="primary" (click)="confirmExportedJob()">OK</button>
            </div>
        </div>
    </div>
</div>