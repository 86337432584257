import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Shipment as ShipmentModel, DeliveryInfo } from "@wearewarp/types/data-model";
import { Stop } from "./route";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { WarpId } from '@wearewarp/universal-libs';

@Component({
    selector: '[stop-list-item]',
    templateUrl: './stop_list_item.html',
    styleUrls: ['./styles.scss', './stop_list_item.scss']
})
export class StopListItem extends Component implements OnChanges {
    @Input() stop: Stop
    @Input() movingMode: boolean = false
    @Input() moving: boolean = false
    @Input() last
    
    @Output() onTriggerMove: EventEmitter<any> = new EventEmitter<any>();
    @Output() onCancelMoving: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMoveAfterMe: EventEmitter<any> = new EventEmitter<any>();

    public stopTypeShortName
    public timeWindow
    public get name() {
      return `${this.stopTypeShortName} ${this.showShipmentCode(this.stop?.info)}`;
    }
    public get type() {
      return this.stop?.info?.type;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.stopTypeShortName = this.stop?.info?.type?.substring(0,1)?.toUpperCase() + this.stop?.info?.type?.substring(1)?.toLocaleLowerCase()
        this.timeWindow = null
        if (this.stop?.info?.windows && this.stop?.info?.windows.length) {
            this.timeWindow = DateUtil.displayTimeWindow(this.stop?.info?.windows[0], {
                timezone: this.stop.info?.addr?.metadata?.timeZoneStandard,
                formatDateOnly: 'MM/DD',
                format: "MM/DD hh:mm A",
            })
        }
    }

    public copyWarpId() {
        Utils.copyTextToClipboard(this.stop.info.id, (e) => {
        });
    }

    public showShipmentCode(shipment) {
        return WarpId.showShipmentCode(shipment)
    }

}